export const slickStyles = {
  '.slick-slider': {
    position: 'relative',
    display: 'block',
    boxSizing: 'border-box',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    KhtmlUserSelect: 'none',
    msTouchAction: 'pan-y',
    touchAction: 'pan-y',
    WebkitTapHighlightColor: 'transparent',
  },
  '.slick-list': {
    position: 'relative',
    display: 'block',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
  },
  '.slick-list:focus': {
    outline: 'none',
  },
  '.slick-list.dragging': {
    cursor: 'pointer',
  },
  '.slick-slider .slick-track, .slick-slider .slick-list': {
    WebkitTransform: 'translate3d(0, 0, 0)',
    MozTransform: 'translate3d(0, 0, 0)',
    msTransform: 'translate3d(0, 0, 0)',
    OTransform: 'translate3d(0, 0, 0)',
    transform: 'translate3d(0, 0, 0)',
  },
  '.slick-track': {
    position: 'relative',
    top: 0,
    left: 0,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '.slick-track:before, .slick-track:after': {
    display: 'table',
    content: '""',
  },
  '.slick-track:after': {
    clear: 'both',
  },
  '.slick-loading .slick-track': {
    visibility: 'hidden',
  },
  '.slick-slide': {
    display: 'none',
    float: 'left',
    height: '100%',
    minHeight: '1px',
  },
  "[dir='rtl'] .slick-slide": {
    float: 'right',
  },
  '.slick-slide img': {
    display: 'block',
  },
  '.slick-slide.slick-loading img': {
    display: 'none',
  },
  '.slick-slide.dragging img': {
    pointerEvents: 'none',
  },
  '.slick-initialized .slick-slide': {
    display: 'block',
  },
  '.slick-loading .slick-slide': {
    visibility: 'hidden',
  },
  '.slick-vertical .slick-slide': {
    display: 'block',
    height: 'auto',
    border: '1px solid transparent',
  },
  '.slick-arrow.slick-hidden': {
    display: 'none',
  },
  '.slick-dots': {
    position: 'relative',
    display: 'flex !important',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    width: '100%',
    height: '32px',
    margin: '32px 0 0 0',
    listStyle: 'none',
    textAlign: 'center',
  },
  '.slick-dots li': {
    position: 'relative',
    display: 'inline-block',
    margin: 0,
    padding: 0,
    cursor: 'pointer',
  },
  '.slick-dots li button': {
    fontSize: 0,
    lineHeight: 0,
    display: 'block',
    width: '8px',
    height: '8px',
    padding: 0,
    cursor: 'pointer',
    color: 'transparent',
    border: 0,
    outline: 0,
    borderRadius: '4px',
    backgroundColor: 'rgba(1, 113, 183, 0.3)',
    transition: 'width 0.3s, background-color 0.3s',
  },
  '.slick-dots li.slick-active button': {
    backgroundColor: 'rgb(1, 113, 183)',
    width: '20px',
  },
  '@media (max-width: 900px)': {
    '.standard-dots': {
      width: '100vw',
      marginLeft: '50%',
      transform: 'translateX(-50%)',
    },
  },
};
