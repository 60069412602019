import axios from 'axios';
import qs from 'qs';

import getConfig from '@/configs/global';

const config = getConfig();
const { publicRuntimeConfig } = config;

const { CMS_URL } = publicRuntimeConfig;

const apiClient = axios.create({
  baseURL: `${CMS_URL}/api`,
  paramsSerializer: params =>
    qs.stringify(params, {
      encodeValuesOnly: true,
    }),
  validateStatus: status => status < 500,
});

const api = {
  getPages: async (query = {}) => {
    return await apiClient.get('/pages', {
      params: query,
    });
  },
  getPageByPathname: async (pathname, locale = 'de') => {
    return await apiClient.get('/pages/pathname', {
      params: { pathname, locale },
    });
  },
  getBreadcrumbsByPathname: async pathname => {
    return await apiClient.get('/pages/pathname/breadcrumbs', {
      params: { pathname },
    });
  },
  getBlogPosts: async (query = {}) => {
    return await apiClient.get('/blog-posts', {
      params: query,
    });
  },
  getBlogPostByPathname: async pathname => {
    return await apiClient.get('/blog-posts/pathname', {
      params: { pathname },
    });
  },
  getBlogPostsByCategoryPathname: async (pathname, page) => {
    return await apiClient.get('/blog-posts/by-category-pathname', {
      params: {
        pathname,
        pagination: {
          page: page || 1,
          withCount: true,
          pageSize: 12,
        },
      },
    });
  },
  getBlogCategories: async () => {
    return await apiClient.get('/blog-categories', {
      params: {
        populate: {
          metadata: {
            populate: '*',
          },
        },
      },
    });
  },
  getReferenceProjects: async (query = {}) => {
    return await apiClient.get('/reference-projects', {
      params: query,
    });
  },
  getReferenceProjectByPathname: async pathname => {
    return await apiClient.get('/reference-projects/pathname', {
      params: { pathname },
    });
  },
  getReferenceProjectsByCategoryPathname: async (pathname, page) => {
    return await apiClient.get('/reference-projects/by-category-pathname', {
      params: {
        pathname,
        pagination: {
          page: page || 1,
          withCount: true,
          pageSize: 9,
        },
      },
    });
  },
  getReferenceProjectsCategories: async () => {
    return await apiClient.get('/reference-categories', {
      params: {
        populate: {
          metadata: {
            populate: '*',
          },
        },
      },
    });
  },
  getMainMenu: async () => {
    return await apiClient.get('/main-menu');
  },
};

export default api;
