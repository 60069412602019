export const globalStyles = {
  ':root': {
    '--background-gray': '#f9f9f9',
    '--white': '#ffffff',
    '--black': '#303030',
    '--primary': '#0171b7',
    '--dark-orange': '#de7f1f',
    '--ck-color-image-caption-background': 'hsl(0, 0%, 97%)',
    '--ck-color-image-caption-text': 'hsl(0, 0%, 20%)',
    '--ck-color-mention-background': 'hsla(341, 100%, 30%, 0.1)',
    '--ck-color-mention-text': 'hsl(341, 100%, 30%)',
    '--ck-color-table-caption-background': 'hsl(0, 0%, 97%)',
    '--ck-color-table-caption-text': 'hsl(0, 0%, 20%)',
    '--ck-highlight-marker-blue': 'hsl(201, 97%, 72%)',
    '--ck-highlight-marker-green': 'hsl(120, 93%, 68%)',
    '--ck-highlight-marker-pink': 'hsl(345, 96%, 73%)',
    '--ck-highlight-marker-yellow': 'hsl(60, 97%, 73%)',
    '--ck-highlight-pen-green': 'hsl(112, 100%, 27%)',
    '--ck-highlight-pen-red': 'hsl(0, 85%, 49%)',
    '--ck-image-style-spacing': '1.5em',
    '--ck-inline-image-style-spacing':
      'calc(var(--ck-image-style-spacing) / 2)',
    '--ck-todo-list-checkmark-size': '16px',
  },
  '*': {
    boxSizing: 'border-box',
  },
  '*::before': {
    boxSizing: 'border-box',
  },
  '*::after': {
    boxSizing: 'border-box',
  },
  'html, body, [id="__next"]': {
    minHeight: '100%',
    height: '100%',
  },
  body: {
    fontFamily: 'Roboto, sans-serif',
    color: 'var(--black)',
    background: 'var(--background-gray)',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    margin: 0,
    padding: 0,
  },
  a: {
    textDecoration: 'none',
    color: 'var(--black)',
  },
  '.cookie-button > button': {
    background: 'var(--white) !important',
    color: 'var(--primary) !important',
    borderColor: 'var(--primary) !important',
  },
  '.ck-content code': {
    backgroundColor: 'hsla(0, 0%, 78%, 0.3)',
    padding: '0.15em',
    borderRadius: '2px',
  },
  '.ck-content blockquote': {
    overflow: 'hidden',
    paddingRight: '1.5em',
    paddingLeft: '1.5em',
    marginLeft: 0,
    marginRight: 0,
    fontStyle: 'italic',
    borderLeft: 'solid 5px hsl(0, 0%, 80%)',
  },
  '.ck-content[dir="rtl"] blockquote': {
    borderLeft: 0,
    borderRight: 'solid 5px hsl(0, 0%, 80%)',
  },
  '.ck-content .text-tiny': {
    fontSize: '0.7em',
  },
  '.ck-content .text-small': {
    fontSize: '0.85em',
  },
  '.ck-content .text-big': {
    fontSize: '1.4em',
  },
  '.ck-content .text-huge': {
    fontSize: '1.8em',
  },
  '.ck-content .marker-yellow': {
    backgroundColor: 'var(--ck-highlight-marker-yellow)',
  },
  '.ck-content .marker-green': {
    backgroundColor: 'var(--ck-highlight-marker-green)',
  },
  '.ck-content .marker-pink': {
    backgroundColor: 'var(--ck-highlight-marker-pink)',
  },
  '.ck-content .marker-blue': {
    backgroundColor: 'var(--ck-highlight-marker-blue)',
  },
  '.ck-content .pen-red': {
    color: 'var(--ck-highlight-pen-red)',
    backgroundColor: 'transparent',
  },
  '.ck-content .pen-green': {
    color: 'var(--ck-highlight-pen-green)',
    backgroundColor: 'transparent',
  },
  '.ck-content .image.image_resized': {
    maxWidth: '100%',
    display: 'block',
    boxSizing: 'border-box',
  },
  '.ck-content .image.image_resized img': {
    width: '100%',
  },
  '.ck-content .image.image_resized > figcaption': {
    display: 'block',
  },
  '.ck-content .image': {
    display: 'table',
    clear: 'both',
    textAlign: 'center',
    margin: '0.9em auto',
    minWidth: '50px',
  },
  '.ck-content .image img': {
    display: 'block',
    margin: '0 auto',
    maxWidth: '100%',
    minWidth: '100%',
  },
  '.ck-content .image-inline': {
    display: 'inline-flex',
    maxWidth: '100%',
    alignItems: 'flex-start',
  },
  '.ck-content .image-inline picture': {
    display: 'flex',
  },
  '.ck-content .image-inline picture, .ck-content .image-inline img': {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: '100%',
  },
  '.ck-content .image-style-block-align-left, .ck-content .image-style-block-align-right': {
    maxWidth: 'calc(100% - var(--ck-image-style-spacing))',
  },
  '.ck-content .image-style-align-left, .ck-content .image-style-align-right': {
    clear: 'none',
  },
  '.ck-content .image-style-side': {
    float: 'right',
    marginLeft: 'var(--ck-image-style-spacing)',
    maxWidth: '50%',
  },
  '.ck-content .image-style-align-left': {
    float: 'left',
    marginRight: 'var(--ck-image-style-spacing)',
  },
  '.ck-content .image-style-align-center': {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '.ck-content .image-style-align-right': {
    float: 'right',
    marginLeft: 'var(--ck-image-style-spacing)',
  },
  '.ck-content .image-style-block-align-right': {
    marginRight: 0,
    marginLeft: 'auto',
  },
  '.ck-content .image-style-block-align-left': {
    marginLeft: 0,
    marginRight: 'auto',
  },
  '.ck-content p + .image-style-align-left, .ck-content p + .image-style-align-right, .ck-content p + .image-style-side': {
    marginTop: 0,
  },
  '.ck-content .image-inline.image-style-align-left, .ck-content .image-inline.image-style-align-right': {
    marginTop: 'var(--ck-inline-image-style-spacing)',
    marginBottom: 'var(--ck-inline-image-style-spacing)',
  },
  '.ck-content .image-inline.image-style-align-left': {
    marginRight: 'var(--ck-inline-image-style-spacing)',
  },
  '.ck-content .image-inline.image-style-align-right': {
    marginLeft: 'var(--ck-inline-image-style-spacing)',
  },
  '.ck-content .image > figcaption': {
    display: 'table-caption',
    captionSide: 'bottom',
    wordBreak: 'break-word',
    color: 'var(--ck-color-image-caption-text)',
    backgroundColor: 'var(--ck-color-image-caption-background)',
    padding: '0.6em',
    fontSize: '0.75em',
    outlineOffset: '-1px',
  },
  '.ck-content .todo-list': {
    listStyle: 'none',
  },
  '.ck-content .todo-list li': {
    marginBottom: '5px',
  },
  '.ck-content .todo-list li .todo-list': {
    marginTop: '5px',
  },
  '.ck-content .todo-list .todo-list__label > input': {
    WebkitAppearance: 'none',
    display: 'inline-block',
    position: 'relative',
    width: 'var(--ck-todo-list-checkmark-size)',
    height: 'var(--ck-todo-list-checkmark-size)',
    verticalAlign: 'middle',
    border: 0,
    left: '-25px',
    marginRight: '-15px',
    right: 0,
    marginLeft: 0,
  },
  '.ck-content .todo-list .todo-list__label > input::before': {
    display: 'block',
    position: 'absolute',
    boxSizing: 'border-box',
    content: '""',
    width: '100%',
    height: '100%',
    border: '1px solid hsl(0, 0%, 20%)',
    borderRadius: '2px',
    transition:
      '250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border',
  },
  '.ck-content .todo-list .todo-list__label > input::after': {
    display: 'block',
    position: 'absolute',
    boxSizing: 'content-box',
    pointerEvents: 'none',
    content: '""',
    left: 'calc(var(--ck-todo-list-checkmark-size) / 3)',
    top: 'calc(var(--ck-todo-list-checkmark-size) / 5.3)',
    width: 'calc(var(--ck-todo-list-checkmark-size) / 5.3)',
    height: 'calc(var(--ck-todo-list-checkmark-size) / 2.6)',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderWidth:
      '0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0',
    transform: 'rotate(45deg)',
  },
  '.ck-content .todo-list .todo-list__label > input[checked]::before': {
    background: 'hsl(126, 64%, 41%)',
    borderColor: 'hsl(126, 64%, 41%)',
  },
  '.ck-content .todo-list .todo-list__label > input[checked]::after': {
    borderColor: 'hsl(0, 0%, 100%)',
  },
  '.ck-content .todo-list .todo-list__label .todo-list__label__description': {
    verticalAlign: 'middle',
  },
  '.ck-content .media': {
    clear: 'both',
    margin: '0.9em 0',
    display: 'block',
    minWidth: '15em',
  },
  '.ck-content .page-break': {
    position: 'relative',
    clear: 'both',
    padding: '5px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.ck-content .page-break::after': {
    content: '""',
    position: 'absolute',
    borderBottom: '2px dashed hsl(0, 0%, 77%)',
    width: '100%',
  },
  '.ck-content .page-break__label': {
    position: 'relative',
    zIndex: 1,
    padding: '0.3em 0.6em',
    display: 'block',
    textTransform: 'uppercase',
    border: '1px solid hsl(0, 0%, 77%)',
    borderRadius: '2px',
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: 'hsl(0, 0%, 20%)',
    background: 'hsl(0, 0%, 100%)',
    boxShadow: '2px 2px 1px hsla(0, 0%, 0%, 0.15)',
    userSelect: 'none',
  },
  '.ck-content .table .ck-table-resized': {
    tableLayout: 'fixed',
  },
  '.ck-content .table td, .ck-content .table th': {
    position: 'relative',
  },
  '.ck-content .table': {
    margin: '0.9em auto',
    display: 'table',
  },
  '.ck-content .table table': {
    overflow: 'hidden',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: '100%',
    height: '100%',
    border: '1px double hsl(0, 0%, 70%)',
  },
  '.ck-content .table table td, .ck-content .table table th': {
    minWidth: '2em',
    padding: '0.4em',
    border: '1px solid hsl(0, 0%, 75%)',
  },
  '.ck-content .table table th': {
    fontWeight: 'bold',
    background: 'hsla(0, 0%, 0%, 5%)',
  },
  '.ck-content[dir="rtl"] .table th': {
    textAlign: 'right',
  },
  '.ck-content[dir="ltr"] .table th': {
    textAlign: 'left',
  },
  '.ck-content .table > figcaption': {
    display: 'table-caption',
    captionSide: 'top',
    wordBreak: 'break-word',
    textAlign: 'center',
    color: 'var(--ck-color-table-caption-text)',
    backgroundColor: 'var(--ck-color-table-caption-background)',
    padding: '0.6em',
    fontSize: '0.75em',
    outlineOffset: '-1px',
  },
  '.ck-content pre': {
    padding: '1em',
    color: 'hsl(0, 0%, 20.8%)',
    background: 'hsla(0, 0%, 78%, 0.3)',
    border: '1px solid hsl(0, 0%, 77%)',
    borderRadius: '2px',
    textAlign: 'left',
    direction: 'ltr',
    tabSize: 4,
    whiteSpace: 'pre-wrap',
    fontStyle: 'normal',
    minWidth: '200px',
  },
  '.ck-content pre code': {
    background: 'unset',
    padding: 0,
    borderRadius: 0,
  },
  '.ck-content .mention': {
    background: 'var(--ck-color-mention-background)',
    color: 'var(--ck-color-mention-text)',
  },
  '@media print': {
    '.ck-content .page-break': {
      padding: 0,
    },
    '.ck-content .page-break::after': {
      display: 'none',
    },
  },
};
