'use client';
import {
  alpha,
  createTheme,
  lighten,
  responsiveFontSizes,
} from '@mui/material/styles';

import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';

let theme = createTheme({
  palette: {
    primary: {
      main: '#0171B7',
      dark: '#0D548C',
      light: '#e7f2f8',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#faa619',
      dark: '#de7f1e',
      light: '#fde4ba',
      contrastText: '#303030',
    },
    primaryLight: {
      main: '#f5f5f5',
      dark: '#e7f2f8',
      contrastText: '#0171B7',
    },
    success: {
      main: '#237b4b',
      dark: '#206640',
      light: '#eef5f1',
      contrastText: '#ffffff',
    },
    error: {
      main: '#c4314b',
      dark: '#952639',
      light: '#edc1c9',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ED6C02',
      dark: '#E65100',
      light: '#FF9800',
      contrastText: '#ffffff',
    },
    info: {
      main: '#0288D1',
      dark: '#01579B',
      light: '#03A9F4',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#212121',
      secondary: '#666666',
      disabled: '#9E9E9E',
    },
    background: {
      default: '#f9f9f9',
    },
    action: {
      disabled: '#707070',
      disabledBackground: '#F6F6F6',
      disabledOpacity: 1,
      hover: '#e7f2f8',
    },
    common: {
      lightBlue: '#8cbddc',
      blue: '#0171b7',
      blueGray: '#c1c7c9',
      darkBlue: '#0d548c',
      veryDarkBlue: '#004073',
      white: '#ffffff',
      black: '#000000',
    },
    divider: '#d5d5d5',
    backdrop: alpha('#f5f5f5', 0.8),
    grayscale: {
      50: '#f9f9f9',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#d5d5d5',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#707070',
      700: '#505050',
      800: '#303030',
    },
    blogCategories: {
      Baubranche: alpha('#22008b', 0.1),
      Baumaschinen: alpha('#004aff', 0.15),
      'klickrent News & Updates': alpha('#01dae8', 0.15),
      Baustelle: alpha('#dbff00', 0.25),
    },
  },
  typography: {
    fontWeightSemiBold: 600,
    fontWeightBlack: 900,
    fontFamilyRedesign:
      '"Roboto Flex", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  boxShadow: {
    elevation1: '0px 2px 6px 0px rgb(0 0 0/16%)',
    elevation2: '0px 2px 16px 0px rgb(0 0 0/16%)',
  },
  zIndex: {
    // Custom zIndexes
    popper: 1000,

    // Default MUI zIndexes
    mobileStepper: 1000,
    // fab - floating action button
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    loader: 1350,
    snackbar: 1400,
    tooltip: 1500,
    heroImage: -99,
  },
  pageMarginHorizontal: '2.25rem',
  pageMarginHorizontalMobile: '1.25rem',
  shape: {
    borderRadiusLarge: 15,
  },
});

theme = createTheme(theme, {
  palette: {
    primaryColorShades: {
      p4: lighten(theme.palette.primary.main, 0.96),
      p8: lighten(theme.palette.primary.main, 0.92),
      p12: lighten(theme.palette.primary.main, 0.88),
      p30: lighten(theme.palette.primary.main, 0.7),
      p50: lighten(theme.palette.primary.main, 0.5),
    },
    secondaryColorShades: {
      p4: lighten(theme.palette.secondary.main, 0.96),
      p8: lighten(theme.palette.secondary.main, 0.92),
      p12: lighten(theme.palette.secondary.main, 0.88),
      p30: lighten(theme.palette.secondary.main, 0.7),
      p50: lighten(theme.palette.secondary.main, 0.5),
    },
    successColorShades: {
      p4: lighten(theme.palette.success.main, 0.96),
      p8: lighten(theme.palette.success.main, 0.92),
      p12: lighten(theme.palette.success.main, 0.88),
      p30: lighten(theme.palette.success.main, 0.7),
      p50: lighten(theme.palette.success.main, 0.5),
    },
    errorColorShades: {
      p4: lighten(theme.palette.error.main, 0.96),
      p8: lighten(theme.palette.error.main, 0.92),
      p12: lighten(theme.palette.error.main, 0.88),
      p30: lighten(theme.palette.error.main, 0.7),
      p50: lighten(theme.palette.error.main, 0.5),
    },
    warningColorShades: {
      p4: lighten(theme.palette.warning.main, 0.96),
      p8: lighten(theme.palette.warning.main, 0.92),
      p12: lighten(theme.palette.warning.main, 0.88),
      p30: lighten(theme.palette.warning.main, 0.7),
      p50: lighten(theme.palette.warning.main, 0.5),
    },
    infoColorShades: {
      p4: lighten(theme.palette.info.main, 0.96),
      p8: lighten(theme.palette.info.main, 0.92),
      p12: lighten(theme.palette.info.main, 0.88),
      p30: lighten(theme.palette.info.main, 0.7),
      p50: lighten(theme.palette.info.main, 0.5),
    },
  },
  typography: {
    // typography settings from website redesign
    h1Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(96),
      lineHeight: 1.167,
      fontWeight: theme.typography.fontWeightSemiBold,
      letterSpacing: '-0.5px',
      fontOpticalSizing: 'none',
    },
    h2Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(60),
      lineHeight: 1.2,
      fontWeight: theme.typography.fontWeightSemiBold,
      letterSpacing: '-0.5px',
      fontOpticalSizing: 'none',
    },
    h3Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(48),
      lineHeight: 1.167,
      fontWeight: theme.typography.fontWeightSemiBold,
      letterSpacing: '0px',
      fontOpticalSizing: 'none',
    },
    h4Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(34),
      lineHeight: 1.235,
      fontWeight: theme.typography.fontWeightSemiBold,
      letterSpacing: '0.25px',
      fontOpticalSizing: 'none',
    },
    h5Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.334,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      fontOpticalSizing: 'none',
    },
    h6Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.6,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.15px',
      fontOpticalSizing: 'none',
    },
    subtitle1Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.75,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.15px',
      fontOpticalSizing: 'none',
    },
    subtitle2Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.1px',
      fontOpticalSizing: 'none',
    },
    body1Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.15px',
      fontOpticalSizing: 'none',
    },
    body2Redesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.15px',
      fontOpticalSizing: 'none',
    },
    buttonLargeRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(15),
      lineHeight: 1.733,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      fontOpticalSizing: 'none',
    },
    buttonMediumRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.714,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      fontOpticalSizing: 'none',
    },
    buttonSmallRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(13),
      lineHeight: 1.692,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      fontOpticalSizing: 'none',
    },
    captionRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.66,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.4px',
      fontOpticalSizing: 'none',
    },
    overlineRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 2.66,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      fontOpticalSizing: 'none',
    },
    avatarLetterRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.14px',
      fontOpticalSizing: 'none',
    },
    inputLabelRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.15px',
      fontOpticalSizing: 'none',
    },
    helperTextRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.667,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.4px',
      fontOpticalSizing: 'none',
    },
    inputTextRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.15px',
      fontOpticalSizing: 'none',
    },
    tooltipRedesign: {
      fontFamily: theme.typography.fontFamilyRedesign,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 1.4,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      fontOpticalSizing: 'none',
    },

    // old typography settings
    h1: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: 1.1,
      fontWeight: theme.typography.fontWeightBlack,
      letterSpacing: '0.00938em',
    },
    h2: {
      fontSize: theme.typography.pxToRem(34),
      lineHeight: 1.18,
      fontWeight: theme.typography.fontWeightBlack,
      letterSpacing: '0.01324em',
    },
    h3: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.33,
      fontWeight: theme.typography.fontWeightMedium,
      fontStyle: 'italic',
      letterSpacing: '0.01875em',
    },
    h4: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.2,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0.016em',
    },
    h5: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.33,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0',
    },
    h6: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.33,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0',
    },
    subtitle1: undefined,
    subtitle2: undefined,
    body1: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.02em',
    },
    body2: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.02em',
    },
    bodySmall1: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.02em',
    },
    bodySmall2: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.02em',
    },
    button: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.02em',
    },
    label: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.17,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.03499em',
    },
    caption: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.17,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.03333em',
    },
    captionRegular: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.17,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.03333em',
    },
    overline: undefined,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: theme.typography.pxToRem(16),
        },
        contained: {
          boxShadow: '0px 2px 6px 0px rgb(0 0 0/16%)',
          '&:hover': { boxShadow: '0px 2px 16px 0px rgb(0 0 0/16%)' },
        },
        sizeMedium: {
          height: 36,
        },
        sizeLarge: {
          height: 48,
        },
        sizeExtraLarge: {
          height: 56,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grayscale[200],
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(0.5),
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: theme.boxShadow.elevation1,
        },
        elevation2: {
          boxShadow: theme.boxShadow.elevation2,
        },
        outlined: {
          borderColor: '#E0E0E0',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root:not(.MuiBackdrop-invisible)': {
            backgroundColor: theme.palette.backdrop,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.grayscale[600],
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          backgroundColor: theme.palette.grayscale[100],
          '&:before': {
            borderBottom: 'transparent',
          },
          '&:not(.Mui-disabled):hover:before': {
            borderBottom: 'transparent !important',
          },
          '&.MuiInputBase-sizeSmall': {
            minHeight: 48,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grayscale[100],
          },
          '&.Mui-disabled:before': {
            borderBottom: `2px solid ${theme.palette.grayscale[600]}`,
          },
          '&.MuiInputBase-hiddenLabel, .MuiAutocomplete-root &.MuiInputBase-hiddenLabel': {
            paddingTop: 0,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 11,
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: (
            <SvgIcon
              name="attention"
              sx={{ color: theme.palette.error.dark }}
            />
          ),
          success: (
            <SvgIcon name="check" sx={{ color: theme.palette.success.light }} />
          ),
        },
      },
      styleOverrides: {
        standardError: {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.dark,
        },
        standardSuccess: {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.light,
        },
        outlinedWarning: {
          color: theme.palette.warning.light,
        },
        icon: {
          svg: {
            alignSelf: 'start',
            marginTop: 3,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: theme.typography.pxToRem(14),
        },
        noOptions: {
          fontSize: theme.typography.pxToRem(14),
          color: theme.palette.common.blue,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grayscale[300],
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'text.primary',
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: theme.typography.pxToRem(14),
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme, {
  variants: [
    'h1Redesign',
    'h2Redesign',
    'h3Redesign',
    'h4Redesign',
    'h5Redesign',
    'h6Redesign',
  ],
});

export default theme;
