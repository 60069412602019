import parsePhoneNumber from 'libphonenumber-js/max';

import getConfig from '@/configs/global';

const {
  publicRuntimeConfig: { BASE_URL },
} = getConfig();

export const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export const isEmailValid = value => {
  if (!value) {
    return true;
  }
  return EMAIL_REGEX.test(value);
};

export const validatePhoneNumber = (value, countryCode = 'DE') => {
  if (!value) {
    return true;
  }
  try {
    const phoneNumber = parsePhoneNumber(value, countryCode);
    return phoneNumber.isValid();
  } catch {
    return false;
  }
};

export const validateVoucher = async value => {
  if (!value) return true;

  try {
    const response = await fetch(`${BASE_URL}/api/validate-voucher`, {
      method: 'POST',
      body: JSON.stringify({ code: value }),
    });
    if (response.status === 204) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

export const isDigit = value => /^[0-9]+$/.test(value) || !value;

export const isDecimal = value => /^\d+,?\d?$/.test(value) || !value;

export const maxZipCodeLength = 20;
